/* work-sans-regular - latin */
@font-face {
     font-family: 'Work Sans';
     font-style: normal;
     font-weight: 400;
     src: url('../fonts/work-sans-v4-latin-regular.eot'); /* IE9 Compat Modes */
     src: local('Work Sans'), local('WorkSans-Regular'),
          url('../fonts/work-sans-v4-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/work-sans-v4-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/work-sans-v4-latin-regular.woff') format('woff'), /* Modern Browsers */
          url('../fonts/work-sans-v4-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/work-sans-v4-latin-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
   }
   
   /* work-sans-500 - latin */
   @font-face {
     font-family: 'Work Sans';
     font-style: normal;
     font-weight: 500;
     src: url('../fonts/work-sans-v4-latin-500.eot'); /* IE9 Compat Modes */
     src: local('Work Sans Medium'), local('WorkSans-Medium'),
          url('../fonts/work-sans-v4-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/work-sans-v4-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/work-sans-v4-latin-500.woff') format('woff'), /* Modern Browsers */
          url('../fonts/work-sans-v4-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/work-sans-v4-latin-500.svg#WorkSans') format('svg'); /* Legacy iOS */
   }
   
   /* work-sans-700 - latin */
   @font-face {
     font-family: 'Work Sans';
     font-style: normal;
     font-weight: 700;
     src: url('../fonts/work-sans-v4-latin-700.eot'); /* IE9 Compat Modes */
     src: local('Work Sans Bold'), local('WorkSans-Bold'),
          url('../fonts/work-sans-v4-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/work-sans-v4-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/work-sans-v4-latin-700.woff') format('woff'), /* Modern Browsers */
          url('../fonts/work-sans-v4-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/work-sans-v4-latin-700.svg#WorkSans') format('svg'); /* Legacy iOS */
   }
   /* poppins-200 - latin */
@font-face {
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 200;
     src: url('../fonts/poppins-v6-latin-200.eot'); /* IE9 Compat Modes */
     src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'),
          url('../fonts/poppins-v6-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/poppins-v6-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/poppins-v6-latin-200.woff') format('woff'), /* Modern Browsers */
          url('../fonts/poppins-v6-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/poppins-v6-latin-200.svg#Poppins') format('svg'); /* Legacy iOS */
   }
   
   /* poppins-200italic - latin */
   @font-face {
     font-family: 'Poppins';
     font-style: italic;
     font-weight: 200;
     src: url('../fonts/poppins-v6-latin-200italic.eot'); /* IE9 Compat Modes */
     src: local('Poppins ExtraLight Italic'), local('Poppins-ExtraLightItalic'),
          url('../fonts/poppins-v6-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/poppins-v6-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/poppins-v6-latin-200italic.woff') format('woff'), /* Modern Browsers */
          url('../fonts/poppins-v6-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/poppins-v6-latin-200italic.svg#Poppins') format('svg'); /* Legacy iOS */
   }
   
   /* poppins-300 - latin */
   @font-face {
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 300;
     src: url('../fonts/poppins-v6-latin-300.eot'); /* IE9 Compat Modes */
     src: local('Poppins Light'), local('Poppins-Light'),
          url('../fonts/poppins-v6-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/poppins-v6-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/poppins-v6-latin-300.woff') format('woff'), /* Modern Browsers */
          url('../fonts/poppins-v6-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/poppins-v6-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
   }
   
   /* poppins-300italic - latin */
   @font-face {
     font-family: 'Poppins';
     font-style: italic;
     font-weight: 300;
     src: url('../fonts/poppins-v6-latin-300italic.eot'); /* IE9 Compat Modes */
     src: local('Poppins Light Italic'), local('Poppins-LightItalic'),
          url('../fonts/poppins-v6-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/poppins-v6-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/poppins-v6-latin-300italic.woff') format('woff'), /* Modern Browsers */
          url('../fonts/poppins-v6-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/poppins-v6-latin-300italic.svg#Poppins') format('svg'); /* Legacy iOS */
   }
   
   /* poppins-regular - latin */
   @font-face {
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 400;
     src: url('../fonts/poppins-v6-latin-regular.eot'); /* IE9 Compat Modes */
     src: local('Poppins Regular'), local('Poppins-Regular'),
          url('../fonts/poppins-v6-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/poppins-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/poppins-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
          url('../fonts/poppins-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/poppins-v6-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
   }
   
   /* poppins-500 - latin */
   @font-face {
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 500;
     src: url('../fonts/poppins-v6-latin-500.eot'); /* IE9 Compat Modes */
     src: local('Poppins Medium'), local('Poppins-Medium'),
          url('../fonts/poppins-v6-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/poppins-v6-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/poppins-v6-latin-500.woff') format('woff'), /* Modern Browsers */
          url('../fonts/poppins-v6-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/poppins-v6-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
   }
   
   /* poppins-500italic - latin */
   @font-face {
     font-family: 'Poppins';
     font-style: italic;
     font-weight: 500;
     src: url('../fonts/poppins-v6-latin-500italic.eot'); /* IE9 Compat Modes */
     src: local('Poppins Medium Italic'), local('Poppins-MediumItalic'),
          url('../fonts/poppins-v6-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/poppins-v6-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/poppins-v6-latin-500italic.woff') format('woff'), /* Modern Browsers */
          url('../fonts/poppins-v6-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/poppins-v6-latin-500italic.svg#Poppins') format('svg'); /* Legacy iOS */
   }
   
   /* poppins-700 - latin */
   @font-face {
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 700;
     src: url('../fonts/poppins-v6-latin-700.eot'); /* IE9 Compat Modes */
     src: local('Poppins Bold'), local('Poppins-Bold'),
          url('../fonts/poppins-v6-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/poppins-v6-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/poppins-v6-latin-700.woff') format('woff'), /* Modern Browsers */
          url('../fonts/poppins-v6-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/poppins-v6-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
   }
   
   /* poppins-700italic - latin */
   @font-face {
     font-family: 'Poppins';
     font-style: italic;
     font-weight: 700;
     src: url('../fonts/poppins-v6-latin-700italic.eot'); /* IE9 Compat Modes */
     src: local('Poppins Bold Italic'), local('Poppins-BoldItalic'),
          url('../fonts/poppins-v6-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/poppins-v6-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/poppins-v6-latin-700italic.woff') format('woff'), /* Modern Browsers */
          url('../fonts/poppins-v6-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/poppins-v6-latin-700italic.svg#Poppins') format('svg'); /* Legacy iOS */
   }
     /* karla-regular - latin */
     @font-face {
          font-family: 'Karla';
          font-style: normal;
          font-weight: 400;
          src: url('../fonts/karla-v7-latin-regular.eot'); /* IE9 Compat Modes */
          src: local('Karla'), local('Karla-Regular'),
               url('../fonts/karla-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
               url('../fonts/karla-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
               url('../fonts/karla-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
               url('../fonts/karla-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
               url('../fonts/karla-v7-latin-regular.svg#Karla') format('svg'); /* Legacy iOS */
     }
     
     /* karla-700 - latin */
     @font-face {
          font-family: 'Karla';
          font-style: normal;
          font-weight: 700;
          src: url('../fonts/karla-v7-latin-700.eot'); /* IE9 Compat Modes */
          src: local('Karla Bold'), local('Karla-Bold'),
               url('../fonts/karla-v7-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
               url('../fonts/karla-v7-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
               url('../fonts/karla-v7-latin-700.woff') format('woff'), /* Modern Browsers */
               url('../fonts/karla-v7-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
               url('../fonts/karla-v7-latin-700.svg#Karla') format('svg'); /* Legacy iOS */
     }
     