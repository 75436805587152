@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import 'components/fonts';
@import 'components/slick';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

body {
    background-color: #ffffff;
}

.panel {
    width:100%;
    background-color: #fafafa;
    padding-top:80px;
    padding-bottom:80px;
    p {
        color: #55616f;
        font-family: "Work Sans";
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
    }
    
    &-dark {
        background-color: #1b2733 !important;
        h2 {
            color: #ffffff;
            @media screen and (min-width: 40em) {
                margin-bottom:20px;
            }
        }
        p {
            color: #8295aa;
            font-family: "Work Sans";
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
        }
    }
    &-top {
        height: 100vh;
        min-height: 600px;
        @media screen and (min-width: 64em) {
            min-height: 800px;
        }
        background-image: url("../img/header_new.png");
        background-size: cover;
        background-position: center top;
        position: relative;
        h1 {
            color: #ffffff;
            margin-bottom:80px;
            @media screen and (min-width: 40em) {
                margin-bottom:55px;
            }
            @media screen and (min-width: 64em) {
                margin-bottom:90px;
            }
        }
        .main {
            height:100vh;
            min-height: 600px;
            position: relative;
            
            @media screen and (min-width: 64em) {
                min-height: 800px;
            }
            .main-text {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                a {
                    display: block;
                    margin-right: auto;
                    margin-left: auto;
                    @media screen and (min-width: 40em) {
                        margin-right: unset;
                        margin-left: unset;
                    }
                }
                .cell {
                    width:80%;
                    margin-left:5%;
                    @media screen and (min-width: 40em) {
                        width:40%;
                        margin-left:15%;
                    }
                    @media screen and (min-width: 64em) {
                        margin-left:0%;
                    }
                }
            }
        }
    }
}

h1 {
    font-size: 30px;
    line-height: 35px;
    font-style: italic;
    font-weight: 200;
    @media screen and (min-width: 64em) {
        font-size: 50px;
        line-height: 55px;
    }
    strong {
        font-style: normal;
        font-weight: 700;
    }
}

h2 {
    color: #1b2733;
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
    font-style: italic;
    margin-bottom: 35px;
    @media screen and (min-width: 40em) {
        font-size: 26px;
    }
    strong {
        font-style: normal;
        font-weight: 700;
    }
    &.medium-center {
        text-align: left;
        @media screen and (min-width: 40em) {
            text-align: center;
        }
    }
    &.big {
        color: #1b2733;
        font-size: 48px;
        font-weight: 500;
        line-height: 52px;
        margin-bottom: 70px;
        width:80%;
        @media screen and (min-width: 40em) {
            font-size: 70px;
            line-height: 70px;
            margin-bottom: 50px;
            width:100%;
        }
        @media screen and (min-width: 64em) {
            margin-bottom: 90px;
        }
    }
    &.h2-w {
        @media screen and (min-width: 40em) {
            width: 70%;
        }
    }
}
h3 {
    font-size: 16px;
    font-weight: 700;
}
.footer {
    width: 100%;
    border-top: 1px solid #ececec;
    
    &-left a,&-right {
        padding:15px;
        color: #8295aa;
        font-family: "Work Sans";
        font-size: 12px;
        font-weight: 500;
        span {
            font-size: 10px;
            font-weight: 400;
        }
    }
    a:hover {
        color: #af6c1b;
    }
    &-left {
        display: flex;
        flex-direction: row;
        justify-content:  center;
        @media screen and (min-width: 40em) {
            justify-content:  left;
        }
    }
    &-right {
        text-align:center;
        @media screen and (min-width: 40em) {
            text-align:right;
        }
    }
}
.blocks {
    div {
        padding:40px;
        @media screen and (min-width: 40em) {
            padding:35px 55px;
        }
        @media screen and (min-width: 64em) {
            padding:40px;
        }
        background-color: #1b2733;
        h3 {
            color: #ffffff;
            margin-bottom:20px;
        }
        p {
            color: #8295aa;
            font-family: "Work Sans";
            font-size: 13px;
            font-weight: 400;
            line-height: 21px;
        }
    }
    div:not(:first-of-type):not(:last-of-type) {
        border-top: 1px solid #fafafa;
        border-bottom: 1px solid #fafafa;
        border-left: none;
        border-right: none;

        @media screen and (min-width: 64em) {
            border-left: 1px solid #fafafa;
            border-right: 1px solid #fafafa;
            border-top: none;
            border-bottom: none;
        }
    }
}
.checks {
    position: absolute;
    background-color: #1b2733;
    padding-top:40px;
    padding-bottom:40px;
    width:100%;
    bottom:0px;
    div {
        color: #ffffff;
        font-family: "Work Sans";
        font-size: 16px;
        font-weight: 400;
        @media screen and (min-width: 64em) {
            text-align: center;
        }
        strong {
            font-weight: 700;
        }
        img {
            margin-right:15px;
        }
    }
}
.check-slider {
    position: absolute;
    background-color: #1b2733;
    padding-top:40px;
    padding-bottom:40px;
    width:100%;
    bottom:0px;
    .orbit-figure {
        color: #ffffff;
        font-family: "Work Sans";
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        strong {
            font-weight: 700;
        }
        img {
            margin-right:15px;
        }
    }
}
.button {
    border-radius: 4px;
    background-color: #af6c1b;
    color: #ffffff;
    font-family: "Work Sans";
    font-size: 16px;
    font-weight: 700;
    width:230px;
    &:hover,&:focus,&:active {
        background-color: #dd8922;
    }
    &.large {
        border-radius: 2px;
        font-size: 18px;
        width:305px;
        @media screen and (min-width: 64em) {
            font-size: 22px;
            width:335px;
        }
    }
}
.logo {
    width:100%;
    margin-top:50px;
    @media screen and (min-width: 40em) {
        margin-top:30px;
    }
    @media screen and (min-width: 64em) {
        margin-top:50px;
    }
}
.service_test {
    display: flex; 
    flex-direction: row;
    padding-top:45px;
    padding-bottom:45px;
    justify-content:  center;
    @media screen and (min-width: 40em) {
        padding-top:70px;
        padding-bottom:70px;
    }
    @media screen and (min-width: 64em) {
        padding-top:45px;
        padding-bottom:45px;
    }

    img:last-of-type {
        margin-left: 50px;
    }
}
.accordion {
    margin-top: 15px;
    .accordion-title {
        color: #1b2733;
        font-size: 16px;
        font-weight: 700;
        border-left:none;
        border-right:none;
        @media screen and (min-width: 64em) {
            font-size: 22px;
            padding-left: 0px;
        }
        &:hover,&:focus {
            background-color: #fff;
        }
    }
    .accordion-content {
        border:none;
        p {
            font-weight: 400;
            color: #637282;
            font-family: "Work Sans";
            font-size: 14px;
            @media screen and (min-width: 64em) {
                line-height: 27px;
            }
            strong {
                display: block;
                font-weight: 700;
                @media screen and (min-width: 64em) {
                    display: initial;
                }
            }
        }
        &:last-child {
            border-bottom: none;
        }
    }
    .accordion-title::before {
        content: '';
        background-image: url("../img/icon_plus.svg");
        background-size: cover;
        background-position: center top;
        width: 23px;
        height: 23px;
        right: 0;
        top: 26px;
    }
    .is-active > .accordion-title::before {
        background-image: url("../img/icon_minus.svg");
        height: 5px;
        top: 35px;
    }
    :last-child:not(.is-active) > .accordion-title {

        border-bottom: none;
    
    }
}
.accordion-container {
    padding-top: 50px;
    padding-bottom: 70px;
    @media screen and (min-width: 40em) {   
        padding-top: 70px;
    }
    @media screen and (min-width: 64em) {   
        padding-top: 100px;
        padding-bottom: 120px;
    }
}
.av {
    color: #8295aa;
    font-family: "Work Sans";
    font-size: 12px;
    font-weight: 400;
    .float-center {
        text-align: center;
    }
}
.logos-container {
    padding-top: 50px;
    padding-bottom: 50px;
    @media screen and (min-width: 40em) {   
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .medium-4:not(:first-of-type) {
        img {
            margin-top:45px;
            @media screen and (min-width: 40em) {   
                margin-top:0px;
            }
        }
    }
}
.steps-container {
    padding-top: 50px;
    padding-bottom: 80px;
    @media screen and (min-width: 40em) {  
        padding-top: 70px;
        padding-bottom: 70px;
    }
    @media screen and (min-width: 64em) {
        padding-top: 185px;
        padding-bottom: 200px;
    }
    p {
        color: #637282;
        font-family: "Work Sans";
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
    }
    .steps {
        margin-top:15px;
        max-width: 230px;
        margin-bottom:50px;
        .step {
            padding: 10px 0px;
            display: flex;
            flex-direction: row;
            justify-content:  center;
            align-items: center;
            div:first-of-type {
                color: #1b2733;
                font-size: 42px;
                font-weight: 700;
                padding-right: 30px;
            }
            div:last-of-type {
                color: #637282;
                font-family: "Work Sans";
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
                text-align: left;
            }
            &:not(:first-of-type) {
                border-top: 1px solid #ececec;
            }
        }

    }
}
.s-p {
    padding-left:0px;
    padding-right:0px;
    @media screen and (min-width: 40em) {  
        padding-left: 25px;
        padding-right:25px;
    }
    @media screen and (min-width: 64em) {
        padding-left:0px;
        padding-right:0px;
    }
}
.e-bike {
    white-space: nowrap;
}
.grid-container {

    padding-left: 25px;
    padding-right: 25px;
}
.big-slider {
    .slick-dots {
        bottom: -50px;
        li button {
            background-image: url("../img/dot_unactive.svg");
            background-size: contain;
            background-position: center top;
            background-repeat: no-repeat;
            background-color: unset;
            color: transparent;
            width: 9px;
            height: 9px;
            margin-left: 8px;
            margin-right: 8px;

            &:hover {
                color: transparent;               
            }
            &:before {
                content: '';
                color: transparent;
            }
        }
        li.slick-active button {
            background-image: url("../img/dot_active.svg");
            width: 15px;
            height: 15px;
            margin-left: 5px;
            margin-right: 5px;
        }
    }
    .orbit-bullets {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
            background-image: url("../img/dot_unactive.svg");
            background-size: contain;
            background-position: center top;
            background-repeat: no-repeat;
            background-color: unset;
            border-radius: 0 !important; 
            width: 9px;
            height: 9px;
            margin: 3px;
            margin-left: 8px;
            margin-right: 8px;
            &.is-active {
                background-image: url("../img/dot_active.svg");
                background-color: unset;
                width: 15px;
                height: 15px;
                margin: 0px;
                margin-left: 5px;
                margin-right: 5px;
            }
            &:hover {
                background-color: unset;
            }
        }
    }
    .orbit-figure {
        position: relative;
        .orbit-caption {
            position: relative;
            margin-bottom: 20px;
            color: #af6c1b;
            font-size: 35px;
            font-weight: 200;
            line-height: 42px;
            background-color: transparent;
            font-style: italic;
            padding: 0px;
            div {
                @media screen and (min-width: 40em) { 
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
                p {
                    color: #8295aa;
                    font-family: "Work Sans";
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 22px;
                    font-style: normal;
                    margin-top:10px;
                    @media screen and (min-width: 64em) {
                        margin-top: 30px;
                    }
                }
            }
            @media screen and (min-width: 40em) {  
                z-index: 3;
                width: 65%;
                height: 300px;
                padding-left: 5rem;
                font-size: 40px;
                line-height: 46px;
                
            }
            @media screen and (min-width: 64em) {
                height: 600px;
                font-size: 71px;
                line-height: 76px;
                padding-left: 0rem;
                width: 60%;
            }
        }
        .orbit-image {
            @media screen and (min-width: 40em) {  
                width: auto;
                top: 0;
                right:0;
                position: absolute;
                z-index: 2;
                height:100%;
            }
        }
    }
}



body.up .top-bar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
    background-color: #eceaea;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
    justify-content: center;
    z-index: 9997; 
    .menu-bar {
        max-width: 1440px;
        margin: 0;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
            -ms-flex-pack: justify;
        width: 100%;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center; 
        -webkit-justify-content: space-around;
        justify-content: space-around;
        @media screen and (min-width: 40em) {
            -webkit-justify-content: space-between;
            justify-content: space-between;
        }
        .logo-h {
            width: 40px;
            max-height: 25px;
            height: 25px;
            background-image: url("../img/WVDF_Fahrrad_Icon.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: top;
            transition: max-height 300ms ease-in-out; 
        }
    }
}
              
body.up .menu-wrapper .menu, body.down .top-bar:hover + .menu-wrapper .menu{
    top: 0px; 
}
body.down .top-bar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
    background-color: #eceaea;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 9997; 
    .menu-bar {
        max-width: 1440px;
        margin: 0;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        width: 100%;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center; 
        -webkit-justify-content: space-around;
        justify-content: space-around;
        @media screen and (min-width: 40em) {
            -webkit-justify-content: space-between;
            justify-content: space-between;
        }
        .logo-h {
            width: 40px;
            max-height: 25px;
            height: 25px;
            background-image: url('../img/WVDF_Fahrrad_Icon.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: top;
            overflow: hidden;
            transition: max-height 300ms ease-in-out; 
        }
    }
}
.top-bar .button {
    margin: 0 4rem 0 0 !important;
    color: #ffffff;
    font-family: "Work Sans";
    font-size: 12px;
    font-weight: 700;
    width: 178px;
    height: 33px;
}

.menu-wrapper {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto; 
    .menu {
        position: fixed;
        right: -100vw;
        top: 0px;
        width: 100vw;
        height: 100vh;
        z-index: 9998;
        transition: all 300ms ease;
        background-color: #1b2733;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        padding: 1rem; 
        .button {
            margin-top: 50px;
            @media screen and (min-width: 40em) {
                margin-top: 100px;
            }
        }
                    
        &.active {
            right: 0px;
            width: 100vw;
            box-shadow: -10px 2px 10px rgba(0, 0, 0, 0.1); 
        }
                    
        ul {
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-justify-content: flex-end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
                        
            h2 {
                display: block;
                max-width: 100%;
                text-align: left;
                width: 100%;
                font-size: 24px;
                font-weight: bold;
                line-height: 24px;
                margin-left: 0;
                font-style: normal;
            }
            li {
                -webkit-flex: 1 0 100%;
                -ms-flex: 1 0 100%;
                flex: 1 0 100%;
                text-align: left;
                            
                a:not(.button) {
                    padding: 1rem 0;
                    color: #ffffff;
                    font-family: Karla;
                    font-size: 20px;
                    font-weight: 700;
                    &:hover {
                        color: #af6c1b;
                    } 
                    @media screen and (min-width: 40em) {
                        font-size: 32px;
                    }
                }
            }
        }
    }
}
              
body .top-bar  {
    transition: all 300ms ease;
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 9997;
    padding: 0.5rem 1rem;
    background: transparent;
    @media print, screen and (min-width: 48em) {
        padding: 0.5rem 2rem; 
    }
    @media screen and (min-width: 64em) {
        padding: 0.5rem 5rem; 
    } 
    .menu-bar {
        max-width: 1440px;
        margin: 0;
        display:none;
        -ms-flex-pack: justify;

        width: 100%;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center; 
        
        -webkit-justify-content: space-around;
        justify-content: space-around;
        @media screen and (min-width: 40em) {
            -webkit-justify-content: space-between;
            justify-content: space-between;
        }
        .logo-h {
            width: 40px;
            max-height: 25px;
            height: 25px;
            background-image: url("../img/WVDF_Fahrrad_Icon.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: top;
            transition: max-height 300ms ease-in-out; 
        }
    }
}
 
body .menu-icons {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; 
    justify-content: flex-end;
}
.menu-toggle-div {
    position: fixed;
    top:0;
    right: 0;
    z-index: 9999;
}

#menu-toggle {
    width: 22px;
    height: 15px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    margin-top: 50px;
    margin-right: 30px;
    padding: 20px;
    @media screen and (min-width: 90em) {
        margin-right: 50px;
    }
    @media screen and (min-width: 110em) {
        margin-right: 100px;
    }
  }
  #menu-toggle.mt-bar {
    margin-top: 23px !important;
    @media screen and (min-width: 40em) {
        margin-top: 20px !important;
    }
  }
  
  #menu-toggle span {
    display: block;
    position: absolute;
    height: 2px;
    background: #ffffff;
    border-radius: 2px;
    opacity: 1;
    width:22px;
    right: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    @media screen and (min-width: 40em) {
        width:31px;
        height: 3px;
    }
  }
  #menu-toggle.mt-bar:not(.active) span {
    background: #000000;
  }
  
  #menu-toggle:hover span,#menu-toggle.mt-bar:not(.active):hover span {
    background: #af6c1b !important;
  }
  #menu-toggle span:nth-child(1) {
    top: 0px;
  }
  
  #menu-toggle span:nth-child(2),#menu-toggle span:nth-child(3) {
    top: 5px;
    width:12px !important;
    @media screen and (min-width: 40em) {
        top: 8px;
        width:16px !important;
    }
  }
  #menu-toggle span:nth-child(3) {
    width:10px !important;
    right:1px;
  }
  
  #menu-toggle span:nth-child(4) {
    top: 10px;
    width:18px !important;
    @media screen and (min-width: 40em) {
        top: 16px;
        width:25px !important;
    }
  }
  
  #menu-toggle.active span:nth-child(1) {
    top: 5px;
    width: 0%;
    height:0px;
    @media screen and (min-width: 40em) {
        top: 8px;
    }
  }
  
  #menu-toggle.active span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 26px !important;
    @media screen and (min-width: 40em) {
        width: 30px !important;
    }
  }
  
  #menu-toggle.active span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 26px !important;
    @media screen and (min-width: 40em) {
        width: 30px !important;
    }
  }
  
  #menu-toggle.active span:nth-child(4) {
    top: 5px;
    width: 0%;
    height:0px;
    @media screen and (min-width: 40em) {
        top: 8px;
    }
  }
@media (hover: hover) {
    #menu-toggle:hover a .line {
      background-color: #8BA81F; } }

.menu ul li {
    transform: translateY(100px);
    opacity: 0;
    overflow: visible;
}
.menu.active ul li {
    transform: translateY(0px); 
    transition: all 0.4s; 
    opacity: 1;
}
.menu.active ul li:nth-child(1) {transition-delay: .08s;}
.menu.active ul li:nth-child(2) {transition-delay: .16s;}
.menu.active ul li:nth-child(3) {transition-delay: .24s;}
.menu.active ul li:nth-child(4) {transition-delay: .32s;}
.menu.active ul li:nth-child(5) {transition-delay: .40s;}
.menu.active ul li:nth-child(6) {transition-delay: .48s;}

.menu .grid-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.footer_bike_logo {
    max-width: 180px;
}